import React, { useEffect } from "react"
import DocLink from "../components/doclink"
import { graphql, useStaticQuery } from "gatsby"

function onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}

const docsFromCategory = (category, edges) => {
	  return edges
	  .filter(edge => edge.node.frontmatter.category === category)
    .map(edge => <li><DocLink key={edge.node.id} doc={edge.node} /></li>);
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`{
    allBlogs: allMdx(sort: { order: ASC, fields: [frontmatter___title] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
      category
          }
        }
      }
    }
  }`);
  
  let uniqueCategories = data.allBlogs.edges.map(edge => edge.node.frontmatter.category).filter(onlyUnique);
  let postsGroupedByCategory = uniqueCategories.map(category => <li>{category}<ul>{docsFromCategory(category, data.allBlogs.edges)}</ul></li>);
  
  
  //const postsByCategories = data.allBlogs.edges.map(edge => <li>{edge.node.frontmatter.category}<ul>{docsFromCategory(edge.node.frontmatter.category, data.allBlogs.edges)}</ul></li>);
  // const Posts = data.allBlogs.edges
    // //.filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    // .map(edge => <li><PostLink key={edge.node.id} post={edge.node} />({edge.node.frontmatter.category})</li>);
    if (data)
      return <div style={{"width":"256px", "position":"fixed", "bottom":"0px", "top":"0px"}}><ul>{postsGroupedByCategory}</ul></div>
    else
      return <>none</>
}
export default IndexPage


// export const pageQuery = graphql`
//   query {
//     allBlogs: allMdx(sort: { order: ASC, fields: [frontmatter___title] }) {
//       edges {
//         node {
//           id
//           excerpt(pruneLength: 250)
//           frontmatter {
//             slug
//             title
// 			category
//           }
//         }
//       }
//     }
//   }
// `